import { RouteInfo } from './sidebar.metadata';

export const PASSROUTES: RouteInfo[] = [
  // {
  //   path: '/pass/dashboard', title: 'Dashboard', icon: 'foh-icon foh-dashboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: '/pass/events/list', title: 'Events', icon: 'fa fa-calendar-check-o', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_events_access"], submenu: []
  },
  {
    alias: "customers", path: '/customers/list', title: 'Profiles', icon: 'foh-icon foh-customers', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview", "permission_ticketscustomers_manage", "permission_ticketscustomers_viewonly"], submenu: []
  },
  {
    path: '/pass/operations', title: 'Check Ins', icon: 'fa fa-glass', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_ticketscustomers_manage", "permission_ticketscustomers_viewonly"], submenu: []
  },
  {
    path: '', title: 'Company Settings', icon: 'foh-icon foh-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_setup_mcompany"],
    submenu: [
      { path: '/mcompany-setup/clientscats', title: 'Clients Categories', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/templates/sms', title: 'SMS Templates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/templates/email', title: 'Email Templates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/templates/whatsapp', title: 'WhatsApp Templates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_superadmin"] },
      { path: '/mcompany-setup/tags', title: 'Tags', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/birthday-reminders', title: 'Birthday Reminders', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Campaign', icon: 'fa fa-comments', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_paymenthandle"],
    submenu: [
      { path: '/campaign/sms-campaign/list', title: 'SMS Campaigns', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_paymenthandle"], submenu: [] },

    ]
  },
  {
    path: '', title: 'Reports', icon: 'fa fa-bar-chart', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reports"],
    submenu: [
      { path: '/reports/generate', title: 'Generate Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/reports/log', title: 'Reports Logs', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  {
    path: '', title: 'Operations', icon: 'foh-icon foh-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_setup_mcompany"],
    submenu: [
      { path: '/operations/import-customers', title: 'Import Customers', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage"], submenu: [] },
      { path: '/operations/log', title: 'Operation Logs', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      // { path: '/operations/import-reservation', title: 'Import Reservation', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,permissions:["permission_reservationcustomersmanage"], submenu: [] },

    ]
  },
  {
    path: '', title: 'Settings', icon: 'foh-icon foh-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_setup_mcompany", "permission_configs", "permission_tablesetup"],
    submenu: [
      { path: '/table-setup', title: 'Layout Setup', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/table-types', title: 'Table Types', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      {
        path: '', title: 'Users Setup', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_usermanagement"],
        submenu: [
          { path: '/users-setup/superadmins', title: 'SuperAdmins', icon: '', class: '', badge: 'SA', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isSuperAdmin: true, isExternalLink: false, submenu: [] },
          { path: '/users-setup/users', title: 'Users List', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/users-setup/users/logs', title: "Users' Activity Log", icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/users-setup/roles', title: 'Roles', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_usermanagement_roles"] },
        ]
      },
      { path: '/changelog', title: 'Changes Log', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  }

];
