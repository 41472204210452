import { Injectable } from '@angular/core';
import { Template } from './template';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@foh/globals';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(private http: HttpClient,
    private globals: Globals) { }

  getAll(params) {
    return this.http.get<Template[]>(this.globals.WEBAPI + this.globals.URL_TEMPLATES_LIST, { params: params });
  }

  get(id) {
    return this.http.get<Template>(this.globals.WEBAPI + this.globals.URL_TEMPLATES_GET,
      {
        params: { id: id }
      });
  }

  add(record: Template) {
    return this.http.post<Template>(this.globals.WEBAPI + this.globals.URL_TEMPLATES_CREATE, record);
  }

  addFormData(formData: FormData) {
    return this.http.post<Template>(this.globals.WEBAPI + this.globals.URL_TEMPLATES_CREATE, formData);
  }

  update(record: Template) {
    return this.http.post<Template>(`${this.globals.WEBAPI}${this.globals.URL_TEMPLATES_UPDATE}`, record);
  }

  updateFormData(formData: FormData) {
    return this.http.post<Template>(`${this.globals.WEBAPI}${this.globals.URL_TEMPLATES_UPDATE}`, formData);
  }


  delete(id: number) {
    return this.http.post(this.globals.WEBAPI + this.globals.URL_TEMPLATES_DELETE,
      { id: id }
    );
  }
}
